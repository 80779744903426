<template>

  <!-- Announcement Record View -->
  <j-page
    :title="pageTitle"
    :loading="loading"
    fill
    id="announcement-record-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Announcement Tools - Viewing -->
      <div v-if="viewMode" class="staff-tools-viewing">
        <j-menu icon="cog" button-type="icon" >

          <!-- Edit -->
          <j-menu-item
            v-if="userCanEdit"
            icon="edit"
            label="Edit Announcement"
            @click="handleEdit"
          />

          <!-- Delete -->
          <j-menu-item
            v-if="userCanDelete"
            icon="times"
            label="Delete Announcement"
            @click="handleDelete"
          />

        </j-menu>

        <!-- Announcement Index Button -->
        <j-button
          type="icon"
          help="Announcements Index"
          icon="list"
          @action="handleIndex"
        />
      </div>

      <!-- Staff Tools - Editing -->
      <div v-else>

        <!-- Actions -->
        <j-actions
          :record="announcement"
          @cancel="handleView"
          @save="handleSave"
        />

      </div>
    </template>

    <!-- Left - Preview -->
    <template #left>

      <!-- Announcement -->
      <AnnouncementTile :announcement="announcement" />

    </template>

    <!-- Right - View / Edit -->
    <template #right>

      <!-- Create Announcement -->
      <AnnouncementForm v-if="showForm" />

      <!-- Router View -->
      <router-view v-else />

    </template>
  </j-page>
</template>

<script>

// Components
import AnnouncementForm from '@/components/Announcements/AnnouncementForm'
import AnnouncementTile from '@/components/widgets/announcements/AnnouncementTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('announcements')

export default {
  name: 'AnnouncementsView',
  // -------------
  // Components ==
  // -------------
  components: {
    AnnouncementForm,
    AnnouncementTile
},
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      if (vm.tag19) {
        vm.fetchAnnouncement(vm.tag19)
      } else {
        vm.NEW_ANNOUNCEMENT()
      }
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'View Announcement'
    }
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    tag19: {
      type: String,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcement'
    ]),
    ...mapGetters([
      'loading',
      'canEdit'
    ]),
    pageTitle () {
      return this.announcement.isNew() ? 'Add Announcement' : this.announcement.$.title
    },
    showForm () {
      return this.$route.name === 'announcements-create'
    },
    // Modes
    viewMode () { return this.$route.name === 'announcements-view' },
    // Policy
    userCanEdit () {
      // Edit if the user is an admin or editor
      return this.$user.isEditor()
    },
    userCanDelete () {
      // Same as editing
      return this.userCanEdit
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchAnnouncement',
      'saveAnnouncement',
      'deleteAnnouncement'
    ]),
    ...mapMutations([
      'NEW_ANNOUNCEMENT'
    ]),
    handleEdit () {
      this.$router.push({ name: 'announcements-edit' })
    },
    handleView () {
      this.$router.push({ name: 'announcements-view' })
    },
    handleSave () {
      this.saveAnnouncement()
        .then(() => this.handleIndex())
    },
    handleDelete () {
      this.deleteAnnouncement()
        .then(() => this.handleIndex())
    },
    handleIndex () {
      this.$router.push({ name: 'announcements-index' })
    }
  }
}
</script>
